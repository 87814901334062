import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Commerce API`}</h1>
    <p>{`The CoreMedia Commerce Hub controls communication of CoreMedia apps with commerce systems by defining a vendor agnostic API
covering the most common eCommerce features and providing a default client-server implementation of this API.`}</p>
    <p><img parentName="p" {...{
        "src": "/3bae729186701ec8947f2cc90626021b/Commerce_Hub_Architecture_Overview_Dia-new.svg",
        "alt": null
      }}></img></p>
    <p><em parentName="p">{`Communicating with the Commerce Client`}</em></p>
    <p>{`All CoreMedia components (CAE, Studio, Headless Server) that need access to the commerce system include a generic Commerce Hub Client. The client
implements the CoreMedia eCommerce API. Therefore, you have a single, manufacturer independent API on CoreMedia side,
for access to the commerce system.`}</p>
    <p>{`The commerce system specific part exists in a service with the commerce system specific connector. The connector
uses the API of the commerce system (often REST) to get the commerce data. In contrast, the generic Commerce Hub client and
the Commerce Adapters use gRPC for internal communication (see `}<a parentName="p" {...{
        "href": "https://grpc.io/%3C/link%3E"
      }}>{`https://grpc.io/`}</a>{` for details), although
this is mostly hidden by the Java base API implementation. Custom Commerce Adapters normally only need to implement the Java
base API and do not have to deal with gRPC.`}</p>
    <p><img parentName="p" {...{
        "src": "/3466df3b954b4b813049a9a6c515f86b/Commerce_Hub_Architecture_Details_Dia.svg",
        "alt": null
      }}></img></p>
    <p>{`CoreMedia offers connectors for several eCommerce systems. See the `}<a parentName="p" {...{
        "href": "https://releases.coremedia.com/cmcc-12"
      }}>{`download page`}</a>{` for a list
of the connectors.`}</p>
    <p><em parentName="p">{`Detailed communication with the Commerce Client`}</em></p>
    <h2>{`Use Cases`}</h2>
    <p>{`With the Commerce API you can, for example, do the following tasks:`}</p>
    <ul>
      <li parentName="ul">{`Adapt existing adapters to your needs`}</li>
      <li parentName="ul">{`Write an adapter for your custom commerce systems`}</li>
    </ul>
    <h2>{`Resources`}</h2>
    <ul>
      <li parentName="ul">{`Browse the `}<a parentName="li" {...{
          "href": "https://documentation.coremedia.com/commerce-adapter-base/artifacts/current/"
        }}>{`Commerce API Javadoc`}</a></li>
      <li parentName="ul">{`Learn how to program your own Commerce Adapter `}<a parentName="li" {...{
          "href": "https://documentation.coremedia.com/cmcc-12/current/webhelp/custom-commerceadapter-en/content/Preface.html"
        }}>{`Custom Commerce Adapter Developer Manual`}</a></li>
      <li parentName="ul">{`Study the `}<a parentName="li" {...{
          "href": "https://github.com/coremedia-contributions/commerce-adapter-mock/tree/1.6.15"
        }}>{`Mock Adapter for Commerce Hub`}</a>{` in combination with the Custom Commerce Adapter Developer Manual to program
your own adapter`}</li>
      <li parentName="ul">{`Study the `}<a parentName="li" {...{
          "href": "https://github.com/CoreMedia/coremedia-headless-client-react"
        }}>{`Sparc example site`}</a>{` to learn how to connect a commerce system with the Headless Server.
Spark is a React example application based on React, TypeScript and the Headless Server of CoreMedia Content Cloud. It contains a
GraphQL schema stitching server, and a commerce mocking server. The stitching server provides a unified GraphQL interface
with an optional waiver of the otherwise necessary commerce connection (GraphQL Mocking Server).`}</li>
      <li parentName="ul">{`Learn about changes in the `}<a parentName="li" {...{
          "href": "https://releases.coremedia.com/cmcc-12/overview/releases/sfcc_adapter"
        }}>{`Releasenotes for Adapter for Salesforce Commerce Cloud`}</a></li>
      <li parentName="ul">{`Learn about changes in the `}<a parentName="li" {...{
          "href": "https://releases.coremedia.com/cmcc-12/overview/releases/hcl_adapter"
        }}>{`Releasenotes for Adapter for HCL Commerce`}</a></li>
      <li parentName="ul">{`Learn about changes in the`}<a parentName="li" {...{
          "href": "https://releases.coremedia.com/cmcc-12/overview/releases/sap_adapter"
        }}>{`Releasenotes for Adapter for SAP Commerce Cloud`}</a></li>
      <li parentName="ul">{`Learn about changes in the `}<a parentName="li" {...{
          "href": "https://releases.coremedia.com/cmcc-12/overview/releases/commercetools_adapter"
        }}>{`Releasenotes for Adapter for commercetools`}</a></li>
      <li parentName="ul">{`Learn about changes in the `}<a parentName="li" {...{
          "href": "https://releases.coremedia.com/cmcc-12/overview/releases/mock_adapter"
        }}>{`Releasenotes for Mock Adapter for Commerce Hub`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      